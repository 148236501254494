import { fetchGet, fetchPut, fetchPost, fetchGetMess, fetchPostPic } from './index'
import { DataAdminParam } from '@/views/RetailValidation/DataAdmin/types'
import { KufriPanelProps, KufirPanelData } from '@/views/System/Kufri/KufriPanelControl/types'
import { KufriActiveProps } from '@/views/System/Kufri/KufriActive/types'
import { NstMappingProps } from '@/views/System/Kufri/NstMapping/types'
import { UpdateMSRPProps } from '@/views/System/Kufri/UpdateMSRP/types'
import { NstGroupProps, VATRateProps } from '@/views/System/NSTGroupMSRP/types'
import { ImPACTMSRPProps } from '@/views/System/IMPACTMSRP/types'

export const getBasicData = (): Promise<DataAdminParam> => {
  return fetchGet('/rvapi/basic/v1/settings/one')
}

export const updateDeferDays = (arg: string) => {
  return fetchPut(`/rvapi/basic/v1/settings/deferDays?deferDays=${arg}`)
}

export const updateDeferExtendDays = (arg: string) => {
  return fetchPut(`/rvapi/basic/v1/settings/deferExtendDays?deferExtendDays=${arg}`)
}

export const updateMdfStopDays = (arg: string) => {
  return fetchPut(`/rvapi/basic/v1/settings/mdfStopDays?mdfStopDays=${arg}`)
}

export const updateVanLicenseDeferDays = (arg: string) => {
  return fetchPut(`/rvapi/basic/v1/settings/vanLicenseDeferDays?vanLicenseDeferDays=${arg}`)
}

// kufri
export const getKufriPanelData = (params = {}): Promise<KufriPanelProps> => {
  return fetchGet('/vapi/kufri/control/init', params)
}

export const getKufriPanelActiveVersionData = (): Promise<any> => {
  return fetchGet('/vapi/kufri/activeVersion')
}

export const findKufriPanelData = (params = {}): Promise<KufirPanelData[]> => {
  return fetchGet('/vapi/kufri/control/data', params)
}

// Kufri Active
export const getKufriActiveData = (params = {}): Promise<KufriActiveProps> => {
  return fetchGet('/vapi/kufri/active/search', params)
}

// Run Kufri Active
export const runKufriActive = (params = {}): Promise<any> => {
  return fetchGetMess('/vapi/kufri/active/first/three', params)
}

// Active Kufri Active
export const activeKufriActive = (params = {}): Promise<any> => {
  return fetchGetMess('/vapi/kufri/active/last/two', params)
}

// 获取KPI
export const getKpiList = (): Promise<string[]> => {
  return fetchGet('/vapi/kufri/kpis')
}

// nst Mapping 
export const getMappingData = (params = {}): Promise<any> => {
  return fetchGet('/vapi/kufri/nstGroup/mapping', params)
}

// save nst Mapping
export const saveNstMapping = (params = {}) => {
  return fetchPostPic('/vapi/kufri/nstGroup/mapping', params)
}

// IMPACT 通过nstGroupMapping批量获取IMPACT CODE的接口
export const getImpactData = (params = {}): Promise<any> => {
  return fetchPost('/vapi/kufri/nstGroup/mapping/impact/list', params);
}

// update MSRP
export const getMSRPData = (params = {}): Promise<UpdateMSRPProps> => {
  return fetchGet('/vapi/msrp/detail', params)
}

// save MSRP
export const saveMSRPData = (params = {}) => {
  return fetchPost('/vapi/msrp/detail', params)
}

// Nst Group
export const getNstGroup = (params = {}): Promise<NstGroupProps> => {
  return fetchGet('/vapi/msrp/nstGroup', params)
}

// 
export const getMakeYearList = (): Promise<any> => {
  return fetchGet(`/vapi/msrp/makeYearList`)
}

// 根据make获取有数据的version
export const getVersionBymake = (params = {}): Promise<{active: boolean; value: string}[]> => {
  return fetchGet(`/vapi/msrp/version`, { params })
}

// save Nst Group MSRP
export const saveNstGroupData = (params = {}) => {
  return fetchPost('/vapi/msrp/nstGroup', params)
}

// confirm Nst Group MSRP
export const confirmNstGroupData = (params: any = {}) => {
  return fetchPut(`/vapi/msrp/nstGroup?version=${params.version}&make=${params.make}&year=${params.year}`)
}

// VAT Rate
export const getVATRate = (params = {}): Promise<VATRateProps> => {
  return fetchGet('/vapi/msrp/vat', params)
}

// get IMPACT MSRP Override
export const getIMPACTMSRP = (params = {}): Promise<ImPACTMSRPProps> => {
  return fetchGet('/vapi/msrp/impact', params)
}

// save IMPACT MSRP Override
export const saveIMPACTMSRP = (params = {}) => {
  return fetchPost('/vapi/msrp/impact', params)
}
