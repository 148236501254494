
import { defineComponent, ref, onMounted} from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { getKufriPanelActiveVersionData } from '@/API/sysParam'

export default defineComponent({
    name: '',
    setup() {
        const router = useRouter()
        const store = useStore()
        const toKufriControl = (make: string) => {
            // const Dom = e.target as HTMLDivElement
             store.commit('updateMake', make)
             router.push({ name: 'KufriPanelControl', params: {make}});
            // if (Dom.className !== 'kufriPanel-box') {
            //     const value = Dom.innerText
            //     // 存储make
            //     store.commit('updateMake', value)
            //     if (value) router.push({ name: 'KufriPanelControl', params: {make: value}});
            // }
        }
        
        const activeVersion = ref({
            PBP: '',
            VAN: '',
            CBU: '',
        })
        onMounted(() => {
            getKufriPanelActiveVersionData().then(res => {
                activeVersion.value = res
            })
        })
        return {
            toKufriControl,
            activeVersion
        }

    }
 });
